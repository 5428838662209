import { default as indexr17QrOVHlLMeta } from "/opt/buildhome/repo/pages/candidate/[id]/index.vue?macro=true";
import { default as updatezOrZNIKPd9Meta } from "/opt/buildhome/repo/pages/candidate/[id]/update.vue?macro=true";
import { default as create6hlXseoStCMeta } from "/opt/buildhome/repo/pages/candidate/create.vue?macro=true";
import { default as index8xXiwLPa5GMeta } from "/opt/buildhome/repo/pages/candidate/index.vue?macro=true";
import { default as indextdqTnIEgwxMeta } from "/opt/buildhome/repo/pages/candidate/job/index.vue?macro=true";
import { default as indexcjRPs6NIN0Meta } from "/opt/buildhome/repo/pages/candidate/submission/index.vue?macro=true";
import { default as indexeGYh27sHnGMeta } from "/opt/buildhome/repo/pages/company/[id]/index.vue?macro=true";
import { default as updateqUpOvyaR99Meta } from "/opt/buildhome/repo/pages/company/[id]/update.vue?macro=true";
import { default as create92LBBQnFBQMeta } from "/opt/buildhome/repo/pages/company/create.vue?macro=true";
import { default as indexqlTSRzDjNmMeta } from "/opt/buildhome/repo/pages/company/index.vue?macro=true";
import { default as indexZysdYNS8aZMeta } from "/opt/buildhome/repo/pages/company/job/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexsxzmzICvS1Meta } from "/opt/buildhome/repo/pages/job/[id]/index.vue?macro=true";
import { default as updatetm0utH3nOfMeta } from "/opt/buildhome/repo/pages/job/[id]/update.vue?macro=true";
import { default as creater9tdInc21zMeta } from "/opt/buildhome/repo/pages/job/create.vue?macro=true";
import { default as indexuMwb03ObfSMeta } from "/opt/buildhome/repo/pages/job/index.vue?macro=true";
import { default as indexCgM5JnfoZ7Meta } from "/opt/buildhome/repo/pages/jobs/[id]/index.vue?macro=true";
import { default as index0DLWOffBfEMeta } from "/opt/buildhome/repo/pages/jobs/index.vue?macro=true";
import { default as subscriptionUqLcZwXThyMeta } from "/opt/buildhome/repo/pages/jobs/subscription.vue?macro=true";
import { default as indexGoCDVXPIQ1Meta } from "/opt/buildhome/repo/pages/library/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as notificationqzXbaWb8jkMeta } from "/opt/buildhome/repo/pages/notification.vue?macro=true";
import { default as candidateEiJP6p6hkcMeta } from "/opt/buildhome/repo/pages/profil/candidate.vue?macro=true";
import { default as companyvijTChaQpiMeta } from "/opt/buildhome/repo/pages/profil/company.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as indexf074ZwuYfdMeta } from "/opt/buildhome/repo/pages/role/index.vue?macro=true";
import { default as addr1aDhN4IOLMeta } from "/opt/buildhome/repo/pages/submission/add.vue?macro=true";
export default [
  {
    name: indexr17QrOVHlLMeta?.name ?? "candidate-id",
    path: indexr17QrOVHlLMeta?.path ?? "/candidate/:id()",
    meta: indexr17QrOVHlLMeta || {},
    alias: indexr17QrOVHlLMeta?.alias || [],
    redirect: indexr17QrOVHlLMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatezOrZNIKPd9Meta?.name ?? "candidate-id-update",
    path: updatezOrZNIKPd9Meta?.path ?? "/candidate/:id()/update",
    meta: updatezOrZNIKPd9Meta || {},
    alias: updatezOrZNIKPd9Meta?.alias || [],
    redirect: updatezOrZNIKPd9Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create6hlXseoStCMeta?.name ?? "candidate-create",
    path: create6hlXseoStCMeta?.path ?? "/candidate/create",
    meta: create6hlXseoStCMeta || {},
    alias: create6hlXseoStCMeta?.alias || [],
    redirect: create6hlXseoStCMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/create.vue").then(m => m.default || m)
  },
  {
    name: index8xXiwLPa5GMeta?.name ?? "candidate",
    path: index8xXiwLPa5GMeta?.path ?? "/candidate",
    meta: index8xXiwLPa5GMeta || {},
    alias: index8xXiwLPa5GMeta?.alias || [],
    redirect: index8xXiwLPa5GMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/index.vue").then(m => m.default || m)
  },
  {
    name: indextdqTnIEgwxMeta?.name ?? "candidate-job",
    path: indextdqTnIEgwxMeta?.path ?? "/candidate/job",
    meta: indextdqTnIEgwxMeta || {},
    alias: indextdqTnIEgwxMeta?.alias || [],
    redirect: indextdqTnIEgwxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/job/index.vue").then(m => m.default || m)
  },
  {
    name: indexcjRPs6NIN0Meta?.name ?? "candidate-submission",
    path: indexcjRPs6NIN0Meta?.path ?? "/candidate/submission",
    meta: indexcjRPs6NIN0Meta || {},
    alias: indexcjRPs6NIN0Meta?.alias || [],
    redirect: indexcjRPs6NIN0Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/candidate/submission/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGYh27sHnGMeta?.name ?? "company-id",
    path: indexeGYh27sHnGMeta?.path ?? "/company/:id()",
    meta: indexeGYh27sHnGMeta || {},
    alias: indexeGYh27sHnGMeta?.alias || [],
    redirect: indexeGYh27sHnGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/company/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateqUpOvyaR99Meta?.name ?? "company-id-update",
    path: updateqUpOvyaR99Meta?.path ?? "/company/:id()/update",
    meta: updateqUpOvyaR99Meta || {},
    alias: updateqUpOvyaR99Meta?.alias || [],
    redirect: updateqUpOvyaR99Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/company/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create92LBBQnFBQMeta?.name ?? "company-create",
    path: create92LBBQnFBQMeta?.path ?? "/company/create",
    meta: create92LBBQnFBQMeta || {},
    alias: create92LBBQnFBQMeta?.alias || [],
    redirect: create92LBBQnFBQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: indexqlTSRzDjNmMeta?.name ?? "company",
    path: indexqlTSRzDjNmMeta?.path ?? "/company",
    meta: indexqlTSRzDjNmMeta || {},
    alias: indexqlTSRzDjNmMeta?.alias || [],
    redirect: indexqlTSRzDjNmMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexZysdYNS8aZMeta?.name ?? "company-job",
    path: indexZysdYNS8aZMeta?.path ?? "/company/job",
    meta: indexZysdYNS8aZMeta || {},
    alias: indexZysdYNS8aZMeta?.alias || [],
    redirect: indexZysdYNS8aZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/company/job/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsxzmzICvS1Meta?.name ?? "job-id",
    path: indexsxzmzICvS1Meta?.path ?? "/job/:id()",
    meta: indexsxzmzICvS1Meta || {},
    alias: indexsxzmzICvS1Meta?.alias || [],
    redirect: indexsxzmzICvS1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/job/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatetm0utH3nOfMeta?.name ?? "job-id-update",
    path: updatetm0utH3nOfMeta?.path ?? "/job/:id()/update",
    meta: updatetm0utH3nOfMeta || {},
    alias: updatetm0utH3nOfMeta?.alias || [],
    redirect: updatetm0utH3nOfMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/job/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: creater9tdInc21zMeta?.name ?? "job-create",
    path: creater9tdInc21zMeta?.path ?? "/job/create",
    meta: creater9tdInc21zMeta || {},
    alias: creater9tdInc21zMeta?.alias || [],
    redirect: creater9tdInc21zMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/job/create.vue").then(m => m.default || m)
  },
  {
    name: indexuMwb03ObfSMeta?.name ?? "job",
    path: indexuMwb03ObfSMeta?.path ?? "/job",
    meta: indexuMwb03ObfSMeta || {},
    alias: indexuMwb03ObfSMeta?.alias || [],
    redirect: indexuMwb03ObfSMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/job/index.vue").then(m => m.default || m)
  },
  {
    name: indexCgM5JnfoZ7Meta?.name ?? "jobs-id",
    path: indexCgM5JnfoZ7Meta?.path ?? "/jobs/:id()",
    meta: indexCgM5JnfoZ7Meta || {},
    alias: indexCgM5JnfoZ7Meta?.alias || [],
    redirect: indexCgM5JnfoZ7Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/jobs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index0DLWOffBfEMeta?.name ?? "jobs",
    path: index0DLWOffBfEMeta?.path ?? "/jobs",
    meta: index0DLWOffBfEMeta || {},
    alias: index0DLWOffBfEMeta?.alias || [],
    redirect: index0DLWOffBfEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: subscriptionUqLcZwXThyMeta?.name ?? "jobs-subscription",
    path: subscriptionUqLcZwXThyMeta?.path ?? "/jobs/subscription",
    meta: subscriptionUqLcZwXThyMeta || {},
    alias: subscriptionUqLcZwXThyMeta?.alias || [],
    redirect: subscriptionUqLcZwXThyMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/jobs/subscription.vue").then(m => m.default || m)
  },
  {
    name: indexGoCDVXPIQ1Meta?.name ?? "library",
    path: indexGoCDVXPIQ1Meta?.path ?? "/library",
    meta: indexGoCDVXPIQ1Meta || {},
    alias: indexGoCDVXPIQ1Meta?.alias || [],
    redirect: indexGoCDVXPIQ1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/library/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notificationqzXbaWb8jkMeta?.name ?? "notification",
    path: notificationqzXbaWb8jkMeta?.path ?? "/notification",
    meta: notificationqzXbaWb8jkMeta || {},
    alias: notificationqzXbaWb8jkMeta?.alias || [],
    redirect: notificationqzXbaWb8jkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/notification.vue").then(m => m.default || m)
  },
  {
    name: candidateEiJP6p6hkcMeta?.name ?? "profil-candidate",
    path: candidateEiJP6p6hkcMeta?.path ?? "/profil/candidate",
    meta: candidateEiJP6p6hkcMeta || {},
    alias: candidateEiJP6p6hkcMeta?.alias || [],
    redirect: candidateEiJP6p6hkcMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profil/candidate.vue").then(m => m.default || m)
  },
  {
    name: companyvijTChaQpiMeta?.name ?? "profil-company",
    path: companyvijTChaQpiMeta?.path ?? "/profil/company",
    meta: companyvijTChaQpiMeta || {},
    alias: companyvijTChaQpiMeta?.alias || [],
    redirect: companyvijTChaQpiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profil/company.vue").then(m => m.default || m)
  },
  {
    name: registerynsVf0yvMzMeta?.name ?? "register",
    path: registerynsVf0yvMzMeta?.path ?? "/register",
    meta: registerynsVf0yvMzMeta || {},
    alias: registerynsVf0yvMzMeta?.alias || [],
    redirect: registerynsVf0yvMzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexf074ZwuYfdMeta?.name ?? "role",
    path: indexf074ZwuYfdMeta?.path ?? "/role",
    meta: indexf074ZwuYfdMeta || {},
    alias: indexf074ZwuYfdMeta?.alias || [],
    redirect: indexf074ZwuYfdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/role/index.vue").then(m => m.default || m)
  },
  {
    name: addr1aDhN4IOLMeta?.name ?? "submission-add",
    path: addr1aDhN4IOLMeta?.path ?? "/submission/add",
    meta: addr1aDhN4IOLMeta || {},
    alias: addr1aDhN4IOLMeta?.alias || [],
    redirect: addr1aDhN4IOLMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/submission/add.vue").then(m => m.default || m)
  }
]