// import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#app';
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.env,
    logErrors: true,
    debug: false,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1.0
  })

  return {
    provide: {
      sentry: Sentry
    }
  }
})
